<template>
  <div class="protocol">
      <portlet :title="$t('PAGES.PROTOCOL.title')">
        <template v-slot:body>
          <protocol-content />
        </template>
      </portlet>
  </div>
</template>

<script>
  import Portlet from "../../../partials/content/Portlet";
  import ProtocolContent from "../../../components/protocol/ProtocolContent";
  import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs.module";
  export default {
    name: "index",
    components: { Portlet, ProtocolContent },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t('PAGES.PROTOCOL.BREAD_CRUMBS_TITLES.FIRST_TITLE'), route: "/protocol" },
        { title: this.$t('PAGES.PROTOCOL.BREAD_CRUMBS_TITLES.CURRENT_TITLE') }
      ]);
    },
    data(){
      return {}
    }
  }
</script>

<style scoped>

</style>
